import * as React from 'react';
import Projects from '../components/Projects/Projects';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';

function IndexPage() {
    return (
        <Layout>
            <Projects />
        </Layout>
    );
}

export default IndexPage;

export function Head() {
    return <SEO title='Designer and Developer' />;
}
