import React from 'react';
import PropTypes from 'prop-types';

import './Section.scss';

function Section({ contained, children, ...props }) {
    return (
        <section className={props.className}>
            {contained && <div className='container'>{children}</div>}
        </section>
    );
}

Section.defaultProps = {
    contained: true,
    children: [],
    className: 'section',
};

Section.propTypes = {
    contained: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Section;
