import React from 'react';
import PropTypes from 'prop-types';

function FilterBtn({ cat, filterClickHandler, isActive }) {
    const classes = [
        'btn-filter',
        `cat-item-${cat.termTaxonomyId}`,
        `category-${cat.slug}`,
        isActive ? 'is-active' : '',
    ];

    if (cat.name === 'all') {
        // eslint-disable-next-line no-param-reassign
        cat.name = '(show all projects)';
    }

    return (
        <button
            type='button'
            className={classes.join(' ')}
            title={`View all projects filed under ${cat.name}`}
            data-filter={`category-${cat.slug}`}
            onClick={filterClickHandler}
        >
            {cat.name}
        </button>
    );
}

FilterBtn.defaultProps = {
    cat: {},
    filterClickHandler: () => {},
    isActive: false,
};

FilterBtn.propTypes = {
    cat: PropTypes.instanceOf(Object),
    filterClickHandler: PropTypes.func,
    isActive: PropTypes.bool,
};

export default FilterBtn;
