import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

function Gallery({ images }) {
    const gallery = images.map(({ image }) => {
        const imageData = getImage(image);
        return (
            <SwiperSlide key={image.databaseId}>
                <GatsbyImage image={imageData} alt={image.altText} />
            </SwiperSlide>
        );
    });

    return (
        <Swiper
            className='test'
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: false }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
        >
            {gallery}
        </Swiper>
    );
}

Gallery.defaultProps = {
    images: {},
};

Gallery.propTypes = {
    images: PropTypes.instanceOf(Object),
};

export default Gallery;
