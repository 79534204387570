import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import AnimateHeight from 'react-animate-height';
import categoryList from '../categoryList';
import Gallery from '../Gallery/Gallery';
import CloseIcon from '../../icons/close.svg';

import './style.scss';

const sanitizedData = (content) => ({
    __html: DOMPurify.sanitize(content),
});

const defaultHeight = 154;

function Project({ project }) {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(defaultHeight);
    let projectClasses = ['project', isOpen ? 'is-open' : 'is-closed'];
    const projectId = `project-${project.databaseId}`;
    const btnCloseClasses = isOpen ? 'is-visible' : '';
    const maskClasses = isOpen ? 'is-hidden is-expanded' : '';
    const categoryClasses = project.categories.nodes.map(
        (cat) => `category-${cat.slug}`
    );
    const categories = categoryList(project.categories.nodes);
    const skills = project.tags.nodes.map((tag, index) => (
        <React.Fragment key={tag.termTaxonomyId}>
            {index ? ', ' : ''}
            {tag.name}
        </React.Fragment>
    ));
    const galleryImages = project.project.projectImages;
    const { websiteLink } = project.project;

    let absoluteWebsiteLink = null;

    if (websiteLink) {
        if (
            websiteLink.startsWith('http://') ||
            websiteLink.startsWith('https://')
        ) {
            absoluteWebsiteLink = websiteLink;
        } else {
            absoluteWebsiteLink = `https://${websiteLink}`;
        }
    }

    projectClasses = [...projectClasses, ...categoryClasses];

    const handleToggle = (e) => {
        const projectElm = e.target.closest('.project');
        setIsOpen((prevState) => !prevState);
        setHeight(
            projectElm.classList.contains('is-closed') ? 'auto' : defaultHeight
        );
    };

    // const handleFocus = (e) => {
    //     const projectContainer = e.target.closest('.project');
    //     if (isOpen) {
    //         console.log('is open', projectContainer);
    //     } else {
    //         console.log('is closed');
    //         if (projectContainer?.nextSibling) {
    //             projectContainer.nextSibling
    //                 .querySelector('.project__mask')
    //                 .focus();
    //         }
    //     }
    // };

    return (
        <AnimateHeight
            id={projectId}
            duration={500}
            height={height} // see props documentation below
            className={projectClasses.join(' ')}
            animationStateClasses={{
                animating: 'is-animating',
                animatingUp: 'is-animating--up',
                animatingDown: 'is-animating--down',
                static: 'is-static',
                animatingToHeightZero: 'is-animating--to-height-zero',
                animatingToHeightAuto: 'is-animating--to-height-auto',
                animatingToHeightSpecific: 'is-animating--to-height-specific',
                staticHeightZero: 'is-static--height-zero',
                staticHeightAuto: 'is-static--height-auto',
                staticHeightSpecific: 'is-static--height-specific',
            }}
        >
            <button
                type='button'
                aria-label='Toggle Project'
                aria-expanded={height !== defaultHeight}
                aria-controls={projectId}
                className={`project__mask ${maskClasses}`}
                onClick={handleToggle}
                // onFocus={handleFocus}
            />
            <header className='project-header'>
                <div className='project-header__categories'>{categories}</div>
                <h2 className='project-header__title'>{project.title}</h2>
                <button
                    type='button'
                    className={`btn-close ${btnCloseClasses}`}
                    aria-label='Collapse Project'
                    onClick={handleToggle}
                >
                    <CloseIcon />
                </button>
            </header>

            <div className='project-gallery'>
                <Gallery images={galleryImages} />
            </div>

            <div className='project-bottom'>
                <div
                    className={`project__information ${
                        websiteLink ? 'has-link' : ''
                    }`}
                >
                    <div
                        className='project__content'
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={sanitizedData(project.content)}
                    />
                    <div className='project__tags'>
                        <span>Skills:</span>
                        {skills}
                    </div>
                    {absoluteWebsiteLink && (
                        <a
                            className='project__link'
                            target='_blank'
                            href={absoluteWebsiteLink}
                            rel='noreferrer'
                        >
                            View Website
                        </a>
                    )}
                </div>
            </div>
        </AnimateHeight>
    );
}

Project.defaultProps = {
    project: [],
};

Project.propTypes = {
    project: PropTypes.instanceOf(Object),
};

export default Project;
