import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import FilterBtn from './FilterBtn';

import './style.scss';

function Filter({ selectedFilters, setSelectedFilters }) {
    const filterClickHandler = (e) => {
        const filter = e.target;
        const filterVal = filter.dataset.filter;

        setSelectedFilters(filterVal);
    };

    const { allWpCategory } = useStaticQuery(
        graphql`
            query CategoryQuery {
                allWpCategory {
                    nodes {
                        name
                        slug
                        termTaxonomyId
                    }
                }
            }
        `
    );

    let catagories = allWpCategory.nodes;

    const allowTaxTermIds = [15, 2, 6];

    catagories = catagories.filter((cat) =>
        allowTaxTermIds.includes(cat.termTaxonomyId)
    );

    const filters = catagories.map((cat, index) => {
        const numOfFilters = catagories.length - 1; // Match Index
        const isActive = `category-${cat.slug}` === selectedFilters;

        let sep = '';

        if (index !== 0) {
            sep = ', ';
        } else if (numOfFilters === index) {
            sep = ', and an ';
        }

        return (
            <React.Fragment key={cat.termTaxonomyId}>
                {sep}
                <FilterBtn
                    cat={cat}
                    isActive={isActive}
                    filterClickHandler={filterClickHandler}
                />
            </React.Fragment>
        );
    });

    return (
        <div className='filter'>
            a {filters}.{' '}
            <button
                className='btn-filter click all'
                type='button'
                data-filter='category-all'
                onClick={filterClickHandler}
            >
                (show all projects)
            </button>
        </div>
    );
}

Filter.defaultProps = {
    selectedFilters: [],
    setSelectedFilters: () => {},
};

Filter.propTypes = {
    selectedFilters: PropTypes.string,
    setSelectedFilters: PropTypes.func,
};

export default Filter;
