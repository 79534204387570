import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './style.scss';

function SiteHeader() {
    const { site } = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        title
                        description
                        siteUrl
                    }
                }
            }
        `
    );

    const { siteMetadata } = site;

    return (
        <header className='site-header'>
            <div className='site-header__inner'>
                <h1 className='site-name'>
                    <a href={siteMetadata.siteUrl}>{siteMetadata.title}</a>
                </h1>
                <div className='site-header__menu'>
                    <a href='mailto:ross@berenson.is'>Email</a> |{' '}
                    <a
                        target='_blank'
                        href='http://www.linkedin.com/in/rossberenson'
                        rel='noreferrer'
                    >
                        Linkedin
                    </a>
                </div>
            </div>
        </header>
    );
}

export default SiteHeader;
