import React from 'react';
import PropTypes from 'prop-types';
import SiteFooter from '../SiteFooter/SiteFooter';
import SiteHeader from '../SiteHeader/SiteHeader';

import '@fontsource/maven-pro'; // Defaults to weight 400.

function Layout({ children }) {
    return (
        <main className='site'>
            <SiteHeader />
            <div className='site-content'>{children}</div>
            <SiteFooter />
        </main>
    );
}

Layout.defaultProps = {
    children: [],
};

Layout.propTypes = {
    children: PropTypes.node,
};

export default Layout;
