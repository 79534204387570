import React from 'react';

const categoryList = (categories) => {
    const categoryExclude = [1, 3, 4, 5, 12, 14];

    let cats = categories.filter(
        (cat) => !categoryExclude.includes(cat.termTaxonomyId)
    );

    cats = cats.map((category, index) => (
        <React.Fragment key={category.termTaxonomyId}>
            {index ? <span className='separator'>,&nbsp;</span> : ''}
            <span className={`${category.slug} cat-${category.termTaxonomyId}`}>
                {category.name}
            </span>
        </React.Fragment>
    ));

    return cats;
};

export default categoryList;
