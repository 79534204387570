import React from 'react';
import './style.scss';

function SiteFooter() {
    const currentYear = new Date().getFullYear();
    return (
        <footer id='footer' className='site-footer'>
            <div className='site-footer__inner'>
                <div className='copyright'>
                    <p>&copy; {currentYear} Ross Berenson</p>
                </div>
                <div className='links'>
                    <a href='mailto:ross@berenson.is'>Email</a> |{' '}
                    <a
                        target='_blank'
                        href='http://www.linkedin.com/in/rossberenson'
                        rel='noreferrer'
                    >
                        Linkedin
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default SiteFooter;
