import React, { useEffect, useState, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Project from '../Project/Project';
import Filter from '../Filter/Filter';
import Section from '../Section/Section';

function Projects() {
    const { allWpProject } = useStaticQuery(
        graphql`
            query ProjectsQuery {
                allWpProject {
                    nodes {
                        databaseId
                        title
                        content
                        tags {
                            nodes {
                                termTaxonomyId
                                name
                                slug
                            }
                        }
                        categories {
                            nodes {
                                termTaxonomyId
                                slug
                                name
                            }
                        }
                        project {
                            projectImages {
                                image {
                                    databaseId
                                    altText
                                    gatsbyImage(
                                        sizes: "portfolio_large"
                                        width: 1100
                                        height: 525
                                        placeholder: BLURRED
                                    )
                                }
                            }
                            websiteLink
                        }
                    }
                }
            }
        `
    );
    const [projects, setProjects] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState('');

    function getFilteredList() {
        if (!selectedFilters || selectedFilters === 'category-all') {
            return projects;
        }
        return projects.filter((project) => {
            const projectCats = project.categories.nodes.map(
                (cat) => `category-${cat.slug}`
            );
            return projectCats.includes(selectedFilters);
        });
    }

    const filteredProjects = useMemo(getFilteredList, [
        selectedFilters,
        projects,
    ]);

    useEffect(() => {
        setProjects(allWpProject.nodes);
    }, [allWpProject.nodes]);

    return (
        <Section contained className='section section--projects'>
            <Filter
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
            />
            <div className='projects'>
                {filteredProjects.map((project) => (
                    <Project key={project.databaseId} project={project} />
                ))}
            </div>
        </Section>
    );
}

export default Projects;
